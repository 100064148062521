<template>
  <div class="row p-5">
    <div class="col-md-4">
      <div class="mt-5 bg-white rounded p-2">
        <h1>404 - Något gick fel!</h1>
        <a class="homeA" @click="onHome()">Hem ></a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "404",
  methods: {
      onHome() {
          this.$router.push('/')
      }
  }
};
</script>

<style scoped>
.homeA {
    text-decoration: none;
}
.homeA:hover {
    text-decoration: underline black;
    cursor: pointer;
}

</style>
